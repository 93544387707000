import { Link, useParams } from 'react-router-dom'
import { Menu, Empty } from 'antd'
import SubLayout from '../components/SubLayout'
import styles from '../styles/Documents.module.scss'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Document } from '../interfaces/Document'
import { api } from '../api/api'
import PermissionWrapper from '../components/PermissionWrapper'
import toast from 'react-hot-toast'
import Modal from '../components/Modal'
import Input from '../components/Input'
import { useForm } from 'react-hook-form'
import { getDocuments } from '../services/documents/getDocuments'
import Loading from '../components/Loading'
import { postFolder } from '../services/documents/postFolder'
import Table from '../components/Table'
import moment from 'moment'

const Documents = () => {

  const [docs, setDocs] = useState<Document[]>([])
  const [tempDocs, setTempDocs] = useState<Document[]>([])
  const [uploading, setUploading] = useState(false)

  const [folderName, setFolderName] = useState('')

  const { aircraftId, folder } = useParams<any>()

  const [loading, setLoading] = useState(true)

  const { register, handleSubmit, reset, formState: { errors } } = useForm()

  const [view, setView] = useState('grid')

  const fetchDocuments = useCallback(async () => {
    try {
      setLoading(true)
      const data = await getDocuments(aircraftId, folder)
      setFolderName(data.folder?.name)
      setDocs(data.documents)
      setTempDocs(data.documents)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }, [aircraftId, folder])

  useEffect(() => {
    fetchDocuments()
  }, [fetchDocuments])

  const inputRef = useRef<any>()

  const [visible, setVisible] = useState(false)

  const createFolder = async (values: any) => {
    const folderData = {
      type: 'folder',
      name: values.folderName,
      folder,
      url: values.folderName.replace(/\s+/g, '-').toLowerCase(),
      aircraftId
    }
    try {
      await postFolder(folderData)
      toast.success('Folder created')
      fetchDocuments()
      setVisible(false)
      reset()
    } catch (error: any) {
      toast.error(error.response.data.message)
    }
  }

  const search = (e: any) => {
    setDocs(tempDocs.filter(doc => doc.name.toLowerCase().includes(e.target.value.toLowerCase())))
  }

  const mobileSearch = (
    <input
      className='input'
      onChange={search}
      placeholder='Search files'
    />
  )

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Upload date',
      dataIndex: 'createdAt',
      render: (text: string) => <span>{moment(text).format('ll')}</span>
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text: string, record: Document) => <span>{record.type === 'folder' ? 'Folder' : record.extension}</span>
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text: string) => <div 
      style={{
        display: 'flex'
      }}
      >
        <div className={styles.icon}>
          <span className="material-symbols-rounded">
            edit
          </span>
        </div>
        <div className={styles.icon}>
          <span className="material-symbols-rounded">
            delete
          </span>
        </div>
      </div>
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setVisible(true)
        }}
        key='New folder'>
        New folder
      </Menu.Item>
      <Menu.Item
        onClick={() => { inputRef.current.click() }}
        key='Upload document'>
        Upload document
      </Menu.Item>
    </Menu>
  );

  const content = (
    <div
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div style={{
        marginRight: 10,
        flex: 1
      }}>
        <input
          onChange={search}
          className='input'
          placeholder='Search files'
        />
      </div>

      <PermissionWrapper
        view='/mtx/documents'
        role='create-edit'
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <button
            onClick={() => setVisible(true)}
            style={{ marginRight: 10 }}
            className='btn btn-blue'
          >
            New folder
          </button>
          <button
            className='btn btn-blue'
            disabled={uploading}
            onClick={() => { inputRef.current.click() }}
          >
            {uploading ? 'Uploading...' : 'Upload'}
          </button>
        </div>
      </PermissionWrapper>
    </div>
  )

  const handleFileUpload = async (e: any) => {
    const files = e.target.files
    if (!files || files.length === 0) {
      return
    }
    setUploading(true)
    const formData = new FormData()
    for (let index = 0; index < files.length; index++) {
      const file = files[index]
      formData.append('files', file)
    }
    formData.append('folder', folder)
    formData.append('aircraftId', aircraftId)

    api.post(`/api/documents/upload/multiple`, formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    ).then(() => {
      toast.success('Documents uploaded')
      fetchDocuments()
      setUploading(false)
    })
  }

  return (
    <SubLayout
      content={content}
      title={folder === 'root' ? 'Documents' : folderName}
      menu={menu}
      mobileSearch={mobileSearch}
    >
      <>
        <input
          multiple
          onChange={handleFileUpload}
          style={{ display: 'none' }}
          type="file"
          ref={inputRef} />
        {
          loading &&
          <Loading />
        }
        {
          !loading && docs.length === 0 &&
          <Empty />
        }
        {
          !loading &&
          <>
            {
              docs.length > 0 &&
              <div className={styles.view}>
                {
                  view === 'grid' ?
                    <div onClick={() => setView('list')} className={styles.icon}>
                      <span className="material-symbols-rounded">
                        view_list
                      </span>
                    </div> :
                    <div onClick={() => setView('grid')} className={styles.icon}>
                      <span className="material-symbols-rounded">
                        grid_view
                      </span>
                    </div>
                }
              </div>
            }
            {
              view === 'grid' ?
                <div className={styles.documentsGrid}>

                  {
                    docs.map(doc => {
                      return (
                        <div key={doc.id} className={styles.documentWrapper}>
                          <div className={styles.actions}>
                            <div className={styles.icon}>
                              <span className="material-symbols-rounded">
                                edit
                              </span>
                            </div>
                            <div className={styles.icon}>
                              <span className="material-symbols-rounded">
                                delete
                              </span>
                            </div>
                          </div>
                          {
                            doc.type === 'folder' ?
                              <Link to={`/mtx/aircraft/${aircraftId}/documents/${doc.url}`}>
                                <svg
                                  style={{
                                    color: '#faad14'
                                  }}
                                  xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                  <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                                </svg>
                                <span>
                                  {doc.name}
                                </span>
                              </Link>
                              :
                              <a target='_blank' href={doc.url} rel="noreferrer">
                                <svg
                                  style={{
                                    color: doc.extension === 'pdf' ? 'red' :
                                      doc.extension === 'docx' ? '#07f' :
                                        doc.extension === 'xlsx' ? 'green' : 'black'
                                  }}

                                  xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                </svg>
                                <span>
                                  {doc.name}
                                </span>
                              </a>
                          }
                        </div>
                      )
                    })
                  }
                </div> :
                <Table
                  data={docs}
                  columns={columns}
                />
            }
          </>
        }
        <Modal
          title="New folder"
          visible={visible}
          onCancel={() => {
            setVisible(false)
          }}
          onClose={() => {
            setVisible(false)
          }}
          onOk={handleSubmit(createFolder)}
          bodyStyle={{
            height: 'auto',
          }}
        >
          <div>
            <Input
              name='folderName'
              label=''
              placeholder='Folder name'
              required
              errors={errors}
              register={register}
            />
          </div>
        </Modal>
      </>
    </SubLayout>
  )
}

export default Documents
