import { FieldValues, UseFormRegister } from 'react-hook-form'

interface Props {
  placeholder?: string,
  type?: any,
  name: string,
  register?: UseFormRegister<FieldValues>,
  required?: boolean,
  defaultValue?: any,
  errors?: any,
  minLength?: number,
  pattern?: any,
  label: string
}

const Input = ({ register, name, required, placeholder, type = 'text', pattern, minLength, defaultValue, errors, label }: Props) => {

  return (
    <div className='input-group'>
      <label htmlFor={name}>{label}</label>
      <input
        className='input'
        defaultValue={defaultValue}
        {
        ...register && {
          ...register(name, {
            required: {
              value: required || false,
              message: 'Required'
            },
            minLength: {
              value: minLength || 0,
              message: 'Minimo ' + minLength + ' caracteres'
            },
            pattern: {
              value: pattern,
              message: 'No valido'
            }

          })
        }
        }
        placeholder={placeholder}
        type={type}
      />
      {
        (errors && errors[name]) &&
        <span className='error'>{errors[name].message}</span>
      }
    </ div>
  )
}

export default Input
