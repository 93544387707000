import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Card from '../components/Card'
import { AuthContext } from '../context/AuthProvider'
import styles from '../styles/Home.module.scss'

const HomeUser = () => {

  const { user } = useContext(AuthContext)

  const links = [
    {
      name: 'Global MTX',
      description: 'Maintenance Tracking',
      icon: <span className="material-symbols-rounded">
        construction
      </span>,
      path: '/mtx/fleet',
      app: 'mtx'
    },
    {
      name: 'Global FBO',
      description: 'Flight scheduling',
      icon: <span className="material-symbols-rounded">
        flight_takeoff
      </span>,
      path: '/fbo',
      app: 'fbo'
    },
  ]

  const filtered = links.filter(link => user.apps?.map(app => app.value).includes(link.app))

  return (
    <div className={styles.home}>

      <div className={styles.header}>
        <h3>Hello, {user.name.split(' ')[0]}!</h3>
        <h4>My apps</h4>
      </div>

      <div className={styles.grid}>

        {
          filtered.map(link => (
            <Card key={link.path} {...link} />
          ))
        }

      </div>

    </div>
  )
}

export default HomeUser