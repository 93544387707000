import { Text, View } from "@react-pdf/renderer";
import moment from "moment";
import { Task } from "../interfaces/Task";
import { styles } from "./styles";
export const reportColumns = [
  {
    title: 'ATA',
    dataIndex: 'ata',
    key: 'ata',
  },
  {
    title: 'Task description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'P/N - S/N',
    dataIndex: 'partNumberSerialNumber',
    key: 'partNumberSerialNumber',
    render: (text: string, record: Task) => {
      return (
        <View>
          {
            record.partNumber && <Text style={styles.fontSmall}>P/N: {record.partNumber.toString().replace("P/N", "")}</Text>
          }

          {
            record.serialNumber && <Text style={styles.fontSmall}>S/N: {record.serialNumber.toString().replace("S/N", "")}</Text>
          }
        </View>
      )
    }
  },
  {
    title: 'Interval',
    dataIndex: 'interval',
    key: 'interval',
    render: (text: string, record: Task) => {
      return (
        <View>
          {
            record.intervalType === 'hour' ?
              <Text style={styles.fontSmall}>{record.hourInterval} hours</Text>
              :
              record.intervalType === 'date' ?
                <Text style={styles.fontSmall}>{record.dateInterval} days</Text>
                :
                record.intervalType === 'oc' ?
                  <Text style={styles.fontSmall}>O/C</Text>
                  :
                  record.intervalType === 'cycle' ?
                    <Text style={styles.fontSmall}>{record.cycleInterval} cycles</Text>
                    :
                    record.intervalType === 'both' ?
                      <View>
                        <Text style={styles.fontSmall}>{record.dateInterval} days</Text>
                        <Text style={styles.fontSmall}>{record.hourInterval} hours</Text>
                      </View>
                      :
                      <Text style={styles.fontSmall}></Text>
          }
        </View>
      )
    }
  },
  {
    title: 'C/W',
    dataIndex: 'cw',
    key: 'cw',
    render: (text: string, record: Task) => <View>
      {
        record.intervalType === 'hour' ?
          <Text style={styles.fontSmall}>{record.cwHour}</Text>
          :
          record.intervalType === 'date' ?
            <Text style={styles.fontSmall}>{moment(record.cwDate).format('ll')}</Text>
            :
            record.intervalType === 'oc' ?
              <Text style={styles.fontSmall}>{moment(record.cwDate).format('ll')}</Text>
              :
              record.intervalType === 'cycle' ?
                <Text style={styles.fontSmall}>{record.cwCycle}</Text>
                :
                record.intervalType === 'both' ?
                  <View>
                    <Text style={styles.fontSmall}>{moment(record.cwDate).format('ll')}</Text>
                    <Text style={styles.fontSmall}>{record.cwHour}</Text>
                  </View>
                  :
                  <Text style={styles.fontSmall}></Text>

      }
    </View>
  },
  {
    title: 'Due',
    dataIndex: 'due',
    key: 'due',
    render: (text: string, record: Task) => <View>
      {
        record.intervalType === 'hour' ?
          <Text style={styles.fontSmall}>{record.dueHour}</Text>
          :
          record.intervalType === 'date' ?
            <Text style={styles.fontSmall}>{moment(record.dueDate).format('ll')}</Text>
            :
            record.intervalType === 'oc' ?
              <Text style={styles.fontSmall}>N/A</Text>
              :
              record.intervalType === 'cycle' ?
                <Text style={styles.fontSmall}>{record.dueCycle}</Text>
                :
                record.intervalType === 'both' ?
                  <View>
                    <Text style={styles.fontSmall}>{moment(record.dueDate).format('ll')}</Text>
                    <Text style={styles.fontSmall}>{record.dueHour}</Text >
                  </View>
                  :
                  <Text style={styles.fontSmall}></Text>
      }
    </View>,

  },
  {
    title: 'Remaining',
    dataIndex: 'remaining',
    key: 'remaining',
    render: (text: string, record: Task) => {
      return (
        <View>
          {
            record.intervalType === 'hour' ?
              <Text style={styles.fontSmall}>{record.remaining && record.remaining.toFixed(2)} hours</Text>
              :
              record.intervalType === 'date' ?
                <Text style={styles.fontSmall}>{record.remaining} days</Text>
                :
                record.intervalType === 'oc' ?
                  <Text style={styles.fontSmall}>N/A</Text>
                  :
                  record.intervalType === 'cycle' ?
                    <Text style={styles.fontSmall}>{record.remaining}</Text>
                    :
                    record.intervalType === 'both' ?
                      <View>
                        <Text style={styles.fontSmall}>{record.remaining.days} days</Text>
                        <Text style={styles.fontSmall}>{record.remaining.hours.toFixed(2)} hours</Text>
                      </View>
                      :
                      <Text style={styles.fontSmall}></Text>
          }
        </View>
      )
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];