import { Link } from 'react-router-dom';
import Home from '../components/Home';
import Aircraft from '../pages/MTX/Fleet/Aircraft';
import AircraftDetail from "../pages/MTX/Fleet/AircraftDetail";
import AirworthinessDirectives from "../pages/AirworthinessDirectives";
import BulletinDetail from '../pages/BulletinDetail';
import DirectiveDetail from '../pages/DirectiveDetail';
import Discrepancies from "../pages/Discrepancies";
import DiscrepancyDetails from "../pages/DiscrepancyDetails";
import Documents from "../pages/Documents";
import DueList from "../pages/DueList";
import FBO from '../pages/FBO/Fbo';
import FlightDetails from '../pages/FBO/Flights/FlightDetails';
import Flights from '../pages/FBO/Flights/Flights';
import Requests from '../pages/Requests';
import ServiceBulletins from "../pages/ServiceBulletins";
import SignOff from "../pages/SignOff";
import TaskDetail from "../pages/TaskDetail";
import Tasks from "../pages/Tasks";
import UserDetails from '../pages/UserDetails';
import Users from '../pages/Users';
import WorkOrderDetail from "../pages/WorkOrderDetail";
import WorkOrders from "../pages/WorkOrders";
import Fleet from '../pages/FBO/Fleet/Fleet';

const NotFound = () => {
  return (
    <div className="empty">
      <div className="icon">
        <span className="material-symbols-rounded">
          error
        </span>
      </div>
      <h3>404. The page you're trying to access doesn't exist.</h3>
      <Link to='/' className='btn btn-link btn-icon'>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
        </svg>
        Back to Home
      </Link>
    </div>
  )
}

export const routes = [
  {
    path: '/',
    exact: true,
    component: Home,
    generalPath: '/'
  },
  {
    path: '/fbo',
    exact: true,
    component: FBO,
    generalPath: '/fbo'
  },
  {
    path: '/404',
    exact: true,
    component: NotFound,
    generalPath: '/'
  },
  {
    path: '/fbo/fleet',
    exact: true,
    component: Fleet,
    generalPath: '/fbo/fleet'
  },
  {
    path: '/:app/fleet',
    exact: true,
    component: Aircraft,
    generalPath: '/mtx/fleet'
  },
  {
    path: '/fbo/flights',
    exact: true,
    component: Flights,
    generalPath: '/fbo/flights'
  },
  {
    path: '/fbo/flights/:id',
    exact: true,
    component: FlightDetails,
    generalPath: '/fbo/flights'
  },
  {
    path: '/users',
    exact: true,
    component: Users,
    generalPath: '/users'
  },
  {
    path: '/users/:id',
    exact: true,
    component: UserDetails,
    generalPath: '/users'
  },
  {
    path: '/:app/aircraft/:aircraftId',
    exact: true,
    component: AircraftDetail,
    generalPath: '/mtx/fleet'
  },
  {
    path: '/fbo/aircraft/:aircraftId',
    exact: true,
    component: AircraftDetail,
    generalPath: '/mtx/fleet'
  },
  {
    path: '/requests',
    exact: true,
    component: Requests,
    generalPath: '/requests'
  },
  {
    path: '/fbo/aircraft/:aircraftId/documents/:folder',
    exact: true,
    component: Documents,
    generalPath: '/fbo/documents'
  },

  //MTX ROUTES

  {
    path: '/:app/aircraft/:aircraftId/documents/:folder',
    exact: true,
    component: Documents,
    generalPath: '/mtx/documents'
  },
  {
    path: '/:app/aircraft/:aircraftId/tasks/:taskId',
    exact: true,
    component: TaskDetail,
    generalPath: '/mtx/tasks'
  },
  {
    path: '/:app/aircraft/:aircraftId/tasks',
    exact: true,
    component: Tasks,
    generalPath: '/mtx/tasks'
  },
  {
    path: '/:app/aircraft/:aircraftId/due-list',
    exact: true,
    component: DueList,
    generalPath: '/mtx/due-list'
  },
  {
    path: '/:app/aircraft/:aircraftId/sign-off',
    exact: true,
    component: SignOff,
    generalPath: '/mtx/sign-off'
  },
  {
    path: '/:app/aircraft/:aircraftId/service-bulletins',
    exact: true,
    component: ServiceBulletins,
    generalPath: '/mtx/service-bulletins'
  },
  {
    path: '/:app/aircraft/:aircraftId/service-bulletins/:bulletinId',
    exact: true,
    component: BulletinDetail,
    generalPath: '/mtx/service-bulletins'
  },
  {
    path: '/:app/aircraft/:aircraftId/airworthiness-directives',
    exact: true,
    component: AirworthinessDirectives,
    generalPath: '/mtx/airworthiness-directives'
  },
  {
    path: '/:app/aircraft/:aircraftId/airworthiness-directives/:directiveId',
    exact: true,
    component: DirectiveDetail,
    generalPath: '/mtx/airworthiness-directives'
  },
  {
    path: '/:app/aircraft/:aircraftId/discrepancies/:discrepancyId',
    exact: true,
    component: DiscrepancyDetails,
    generalPath: '/mtx/discrepancies'
  },
  {
    path: '/:app/aircraft/:aircraftId/discrepancies',
    exact: true,
    component: Discrepancies,
    generalPath: '/mtx/discrepancies'
  },
  {
    path: '/:app/aircraft/:aircraftId/work-orders/:workOrderId',
    exact: true,
    component: WorkOrderDetail,
    generalPath: '/mtx/work-orders'
  },
  {
    path: '/:app/aircraft/:aircraftId/work-orders',
    exact: true,
    component: WorkOrders,
    generalPath: '/mtx/work-orders'
  },
]

