import { Tag, Menu, Modal, Form, Row, Col, Input, Select, InputNumber, DatePicker, message, Table } from 'antd'
import { api } from '../api/api'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import SubLayout from '../components/SubLayout'
import { VerticalTable } from '../components/VerticalTable'
import { AircraftContext } from '../context/AircraftProvider'
import { Task } from '../interfaces/Task'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { reportColumns } from '../helpers/reportColumns'
import { Report } from '../components/Report'
import PermissionWrapper from '../components/PermissionWrapper'


const DueList = () => {

  const { aircraftElement, setAircraftElement } = useContext(AircraftContext)

  const { aircraftId } = useParams<any>()

  const [tasks, setTasks] = useState<Task[]>([])
  const [tempTasks, setTempTasks] = useState<Task[]>([])

  const [visible, setVisible] = useState(false)

  const [form] = Form.useForm();

  const [intervalType, setIntervalType] = useState<any>(null)
  const [doc, setDoc] = useState(false)


  useEffect(() => {
    if (!aircraftElement) {
      api.get(`/api/aircraft/${aircraftId}`).then(({ data }) => {
        setAircraftElement(data.aircraft)

      }).catch(error => console.log(error))
    }

  }, [aircraftId, setAircraftElement, aircraftElement])


  useEffect(() => {
    getAircraftTasks()
  }, [])

  const getAircraftTasks = () => {
    api.get<any>(`/api/tasks/aircraft/${aircraftId}`).then(({ data }) => {
      setTasks(data.tasks)
      setTempTasks(data.tasks)
    }).catch(error => console.log(error))
  }

  const openModal = () => {
    setVisible(true)
  }

  const closeModal = () => {
    setVisible(false)
    form.resetFields()
  }

  const createTask = () => {
    form.validateFields().then((values) => {
      api.post('/api/tasks', {
        ...values,
        aircraft: aircraftId
      }).then(() => {
        message.success('Task created')
        closeModal()
        getAircraftTasks()
      })
    })
  }

  const search = (e: any) => {
    setTasks(tempTasks.filter(task => task.description.toLowerCase().includes(e.target.value.toLowerCase())))
  }

  const showDue = () => {
    setDue(true)
    setTasks(tempTasks.filter(task => task.status === 'DUE'))

  }

  const showAll = () => {
    setDue(false)
    setTasks(tempTasks)
  }

  const [due, setDue] = useState(false)

  const menu = (
    <Menu>
      <Menu.Item
        onClick={openModal}
        key='Create task'>
        Create task
      </Menu.Item>
      {
        doc ?
          <PDFDownloadLink
            document={<Report
              logo={aircraftElement.companyLogo}
              columns={reportColumns}
              aircraft={aircraftElement}
              title='Status report'
              data={tasks}
            />
            }
            fileName={`Status_report.pdf`}>
            {({ loading }) =>
              loading ? 'Loading document...' : <Menu.Item >Download report</Menu.Item>
            }
          </PDFDownloadLink>
          :

          <Menu.Item
            onClick={() => { setDoc(true) }}
          >
            Generate report
          </Menu.Item>
      }
      {
        due ?
          <Menu.Item
            onClick={showAll}
          >
            Show all
          </Menu.Item>
          :
          <Menu.Item
            onClick={showDue}
          >
            Show due
          </Menu.Item>
      }
    </Menu>

  );

  const content = (
    <div
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div style={{
        marginRight: 10,
        flex: 1
      }}>
        <input
          onChange={search}
          className='input'
          placeholder='Search'
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        {
          due ?
            <button
              className='btn btn-blue'
              style={{ marginRight: 10 }}
              onClick={showAll}
            >
              Show all
            </button>
            :
            <button
              className='btn btn-blue'
              style={{ marginRight: 10 }}
              onClick={showDue}
            >
              Show due
            </button>
        }
        <PermissionWrapper
          view='/mtx/tasks'
          role='create-edit'
        >
          <button
            className='btn btn-blue'
            style={{ marginRight: 10 }}
            onClick={openModal}
          >
            Create new task
          </button>
        </PermissionWrapper>

        {
          doc ?

            <PDFDownloadLink
              document={<Report
                logo={aircraftElement.companyLogo}
                columns={reportColumns}
                aircraft={aircraftElement}
                title='Status report'
                data={tasks}
              />
              }
              fileName={`Status_report.pdf`}>
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : <button className='btn btn-blue'>Download report</button>
              }
            </PDFDownloadLink>
            :
            <button
              className='btn btn-blue'
              onClick={() => { setDoc(true) }}
            >
              Generate report
            </button>
        }
      </div>
    </div>
  )

  const mobileSearch = (
    <Input
      onChange={search}
      style={{ width: '100%' }}
      placeholder='Search tasks'
    />
  )

  const columns = [
    {
      title: 'ATA',
      dataIndex: 'ata',
      key: 'ata',
    },
    {
      title: 'Task description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'P/N - S/N',
      dataIndex: 'partNumberSerialNumber',
      key: 'partNumberSerialNumber',
      render: (text: string, record: Task) => {
        return (
          <>
            {
              record?.partNumber && <span className='block mb-10'>P/N: {record?.partNumber.toString().replace("P/N", "")}</span>
            }

            {
              record?.serialNumber && <span className='block mb-10'>S/N: {record?.serialNumber.toString().replace("S/N", "")}</span>
            }
          </>
        )
      }
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: 'Interval',
      dataIndex: 'interval',
      key: 'interval',
      render: (text: string, record: Task) => {
        return (
          <>
            {
              record.intervalType === 'hour' ?
                <span>{record.hourInterval} hours</span>
                :
                record.intervalType === 'date' ?
                  <span>{record.dateInterval} days</span>
                  :
                  record.intervalType === 'oc' ?
                    <span>O/C</span>
                    :
                    record.intervalType === 'cycle' ?
                      <span>{record.cycleInterval} cycles</span>
                      :
                      record.intervalType === 'both' ?
                        <>
                          <span className='block mb-10'>{record.dateInterval} days</span>
                          <span className='block mb-10'>{record.hourInterval?.toFixed(2)} hours</span>
                        </>
                        :
                        <span></span>
            }
          </>
        )
      }
    },
    {
      title: 'C/W',
      dataIndex: 'cw',
      key: 'cw',
      render: (text: string, record: Task) => <>
        {
          record.intervalType === 'hour' ?
            <>{record.cwHour}</>
            :
            record.intervalType === 'date' ?
              <>{moment(record.cwDate).format('ll')}</>
              :
              record.intervalType === 'oc' ?
                (record.cwDate ? <span>{moment(record.cwDate).format('ll')}</span> : 'N/A')
                :
                record.intervalType === 'cycle' ?
                  <span>{record.cwCycle}</span>
                  :
                  record.intervalType === 'both' ?
                    <>
                      <span className='block mb-10'>{moment(record.cwDate).format('ll')}</span>
                      <span className='block mb-10'>{record.cwHour?.toFixed(2)}</span>
                    </>
                    :
                    <span></span>

        }
      </>
    },
    {
      title: 'Due',
      dataIndex: 'due',
      key: 'due',
      render: (text: string, record: Task) => <>
        {
          record.intervalType === 'hour' ?
            <>{record.dueHour}</>
            :
            record.intervalType === 'date' ?
              <>{moment(record.dueDate).format('ll')}</>
              :
              record.intervalType === 'oc' ?
                <span>N/A</span>
                :
                record.intervalType === 'cycle' ?
                  <span>{record.dueCycle}</span>
                  :
                  record.intervalType === 'both' ?
                    <>
                      <span className='block mb-10'>{moment(record.dueDate).format('ll')}</span>
                      <span className='block mb-10'>{record.dueHour?.toFixed(2)}</span>
                    </>
                    :
                    <span></span>
        }
      </>,
    },
    {
      title: 'Remaining',
      dataIndex: 'remaining',
      key: 'remaining',
      render: (text: string, record: Task) => {
        return (
          <>
            {
              record.intervalType === 'hour' ?
                <span>{record.remaining.toFixed(2)} hours</span>
                :
                record.intervalType === 'date' ?
                  <span>{record.remaining} days</span>
                  :
                  record.intervalType === 'oc' ?
                    <span>N/A</span>
                    :
                    record.intervalType === 'cycle' ?
                      <span>{record.remaining} cycles</span>
                      :
                      record.intervalType === 'both' ?
                        <>
                          <span className='block mb-10'>{record.remaining.days} days</span>
                          <span className='block mb-10'>{record.remaining.hours.toFixed(2)} hours</span>
                        </>
                        :
                        <span></span>
            }
          </>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: Task) => <Tag color={record.status === 'OK' ? 'green' : 'red'} >{text}</Tag>
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render: (text: string, record: Task) => <Link className='btn btnBlue' to={`/aircraft/${aircraftId}/tasks/${record.id}`}>View</Link>
    },
  ]



  return (
    <SubLayout mobileSearch={mobileSearch} content={content} title='Due list' menu={menu}>
      <div className='tasks'>
        <div className="mobileTable">
          <VerticalTable data={tasks} columns={columns} />
        </div>
        <div className="webTable">
          <Table dataSource={tasks} columns={columns} />
        </div>
        <Modal
          visible={visible}
          title="Create new task"
          okText="Create"
          cancelText="Cancel"
          onCancel={closeModal}
          onOk={createTask}
          centered
          width='80%'
          bodyStyle={{
            height: '60vh',
            overflowY: 'scroll'
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="workOrderForm"
          >
            <Row gutter={[{ xs: 8, sm: 12, md: 24 }, { xs: 4, sm: 12, md: 8 }]}>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item
                  name="partNumber"
                  label="Part number"

                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6} >
                <Form.Item
                  name="serialNumber"
                  label="Serial Number"

                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} >
                <Form.Item
                  name="description"
                  label="Task description"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Input.TextArea style={{ resize: 'none' }} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} >
                <Form.Item
                  name="notes"
                  label="Notes"
                >
                  <Input.TextArea style={{ resize: 'none' }} />
                </Form.Item>
              </Col>
            </Row>


            <Row gutter={[{ xs: 8, sm: 12, md: 24 }, { xs: 4, sm: 12, md: 8 }]}>

              <Col xs={24} sm={12} lg={6} >
                <Form.Item
                  name="intervalType"
                  label="Interval Type"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Select
                    onChange={(e) => {
                      setIntervalType(e);
                    }}>
                    <Select.Option key='oc' value='oc'>
                      O/C
                    </Select.Option>
                    <Select.Option key='hour' value='hour'>
                      Hour
                    </Select.Option>
                    <Select.Option key='date' value='date'>
                      Date
                    </Select.Option>
                    <Select.Option key='cycle' value='cycle'>
                      Cycle
                    </Select.Option>
                    <Select.Option key='both' value='both'>
                      Both
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              {
                (intervalType === 'hour' || intervalType === 'both') &&
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item
                    name="hourInterval"
                    label="Hour Interval"

                  >
                    <InputNumber />
                  </Form.Item>
                </Col>
              }
              {
                (intervalType === 'date' || intervalType === 'both') &&
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item
                    name="dateInterval"
                    label="Date interval"

                  >
                    <InputNumber />
                  </Form.Item>
                </Col>
              }
              {
                intervalType === 'cycle' &&
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item
                    name="cycleInterval"
                    label="Cycle Interval"

                  >
                    <InputNumber />
                  </Form.Item>
                </Col>
              }
            </Row>

            <Row gutter={[{ xs: 8, sm: 12, md: 24 }, { xs: 4, sm: 12, md: 8 }]}>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item
                  name="ata"
                  label="ATA"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              </Col>
              {
                (intervalType === 'date' || intervalType === 'both' || intervalType === 'oc') &&
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item
                    name="cwDate"
                    label="CW date"

                  >
                    <DatePicker />
                  </Form.Item>
                </Col>
              }
              {
                (intervalType === 'hour' || intervalType === 'both' || intervalType === 'oc') &&
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item
                    name="cwHour"
                    label="CW hour"

                  >
                    <InputNumber />
                  </Form.Item>
                </Col>
              }
              {
                intervalType === 'cycle' &&
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item
                    name="cwCycle"
                    label="CW cycle"

                  >
                    <InputNumber />
                  </Form.Item>
                </Col>
              }
            </Row>
            <Row gutter={[{ xs: 8, sm: 12, md: 24 }, { xs: 4, sm: 12, md: 8 }]}>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item
                  name="appliesTo"
                  label="Applies To"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Select>
                    {
                      aircraftElement?.status?.map((item: any) =>
                        <Select.Option key={item.itemId} value={item.itemId}>
                          {item.itemName}
                        </Select.Option>
                      )
                    }
                  </Select>
                </Form.Item>
              </Col>

            </Row>
          </Form>
        </Modal>
      </div>
    </SubLayout>
  )
}

export default DueList
