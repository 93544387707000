import { api } from '../api/api'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Menu, Modal, Form, Row, Col, Input, Select, Button, message, Table } from 'antd'
import { useParams } from 'react-router'
import SubLayout from '../components/SubLayout'
import { VerticalTable } from '../components/VerticalTable'
import { SignOffData, SignOffElement } from '../interfaces/SignOff'
import { WorkOrderData, WorkOrderElement } from '../interfaces/WorkOrder'
import PermissionWrapper from '../components/PermissionWrapper'

const SignOff = () => {

  const signOffColumns = [
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Register Date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (text: string) => moment(text).format('lll')
    },
    {
      title: 'Work Order ID',
      dataIndex: 'workOrder',
      key: 'workOrder',
      render: (text: string, record: SignOffElement) => record.workOrder.number
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Attachments',
      dataIndex: 'attachments',
      key: 'attachments',
    },
  ]

  const [signOff, setSignOff] = useState<SignOffElement[]>([])

  const [workOrders, setWorkOrders] = useState<WorkOrderElement[]>([])

  const [temp, setTemp] = useState<SignOffElement[]>([])

  useEffect(() => {
    getAircraftSignOff()
    getAircraftWorkOrders()
  }, [])

  const getAircraftWorkOrders = () => {
    api.get<WorkOrderData>(`/api/work-orders/aircraft/${aircraftId}`).then(({ data }) => {
      setWorkOrders(data.workOrders)
    }).catch(error => console.log(error))
  }

  const { aircraftId } = useParams<any>()

  const [visible, setVisible] = useState(false)

  const openModal = () => {
    setVisible(true)
  }

  const closeModal = () => {
    setVisible(false)
    form.resetFields()
  }

  const createSignOff = () => {
    form.validateFields().then((values) => {
      api.post('/api/sign-off', {
        ...values,
        status: 'New',
        aircraft: aircraftId
      }).then(() => {
        message.success('Sign off created')
        closeModal()
        getAircraftSignOff()
      })
    })
  }

  const [form] = Form.useForm();

  const menu = (
    <Menu>
      <Menu.Item
        onClick={openModal}
        key='Create sign off'>
        Create sign off
      </Menu.Item>
    </Menu>
  );

  const search = (e: any) => {
    setSignOff(temp.filter(item => item.title.toLowerCase().includes(e.target.value.toLowerCase())))
  }

  const content = (
    <div
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div style={{
        marginRight: 10,
        width: '100%',
      }}>
        <Input
          onChange={search}
          style={{ width: '100%' }}
          placeholder='Search sign off' />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <PermissionWrapper
          role='create-edit'
          view='/mtx/sign-off'
        >
          <button
            style={{ marginRight: 10 }}
            onClick={openModal}
            className='btn btn-blue'
          >Create sign off</button>
        </PermissionWrapper>
      </div>
    </div>
  )

  const getAircraftSignOff = () => {
    api.get<SignOffData>(`/api/sign-off/aircraft/${aircraftId}`).then(({ data }) => {
      setSignOff(data.signOffs)
      setTemp(data.signOffs)
    }).catch(error => console.log(error))
  }

  const mobileSearch = (
    <Input
      onChange={search}
      style={{ width: '100%' }}
      placeholder='Search sign off'
    />
  )

  return (
    <SubLayout
      content={content}
      title='Sign off'
      menu={menu}
      mobileSearch={mobileSearch}
    >

      <>
        <div className='signOff'>
          <div className="mobileTable">
            <VerticalTable columns={signOffColumns} data={signOff} />
          </div>

          <div className="webTable">
            <Table columns={signOffColumns} dataSource={signOff} />
          </div>
        </div>

        <Modal
          visible={visible}
          title="Create sign off"
          okText="Create"
          cancelText="Cancel"
          onCancel={closeModal}
          centered
          width='80%'
          onOk={createSignOff}
          bodyStyle={{
            height: '65vh',
            overflowY: 'scroll'
          }}

        >
          <Form
            form={form}
            layout="vertical"
            name="signOffForm"
          >
            <Row gutter={[{ xs: 8, sm: 12, md: 24 }, { xs: 4, sm: 12, md: 8 }]}>
              <Col xs={24} >
                <Form.Item
                  name="number"
                  label="Number"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} >
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24}  >
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Input.TextArea style={{ resize: 'none' }} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="workOrder"
                  label="Work Order"
                  rules={[{
                    required: true,
                    message: 'Required'
                  }]}
                >
                  <Select
                    showSearch
                    style={{ width: 200, borderRadius: 6 }}
                    placeholder="Select "
                    optionFilterProp="children"

                  >
                    {
                      workOrders.map((item) =>
                        <Select.Option
                          key={item.id}
                          value={item.id}
                        >
                          {`${item.number} - ${item.title}`}
                        </Select.Option>
                      )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} >
                <Form.Item
                  name="attachments"

                >
                  <div>
                    {/* {
                      attachments.length > 0 &&
                      attachments.map((attachment) => {
                        return (
                          <FilePreview attachment={attachment} setAttachments={setAttachments} attachments={attachments} />
                        )
                      })
                    } */}
                  </div>
                  <div style={{ display: 'none' }}>
                    <Input />
                  </div>
                  <Button
                    // loading={loadingFiles}
                    /* icon={<CloudUploadOutlined style={{ fontSize: 20, verticalAlign: 'middle' }} />} */
                    //onClick={handleInputClick}
                    type="primary" block>{/* {loadingFiles ? "Uploading..." : attachments.length > 0 ? "Add more attachments" : "Add attachments"} */}
                    Attach files
                  </Button>
                  <input
                    multiple
                    // onChange={handleFileChange}
                    style={{ display: 'none' }}
                    type="file"
                    id="fileInput" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    </SubLayout>
  )
}

export default SignOff
