import React, { FC } from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'
import ReactDatePicker from 'react-datepicker'

interface Props {
  control?: Control<FieldValues, any>,
  name: string,
  errors?: {
    [x: string]: any;
  },
  label: string,
  required?: boolean,
  selectedDate: Date | null,
  setSelectedDate: any
}

const DatePicker: FC<Props> = ({ name, label, required, control, errors, selectedDate, setSelectedDate }) => {

  return (
    <div className="input-group">
      <label>{label}</label>
      <Controller
        rules={{
          required: {
            value: required || false,
            message: 'Required'
          }
        }}
        name={name}
        control={control}
        render={({ field: { onChange, value } }) =>
          <ReactDatePicker
            dateFormat='dd-MM-yyyy'
            minDate={new Date()}
            value={value}
            selected={selectedDate}
            onChange={(date) => {
              onChange(date)
              setSelectedDate(date)
            }}
          />

        }
      />
      {
        (errors && errors[name]) &&
        <span className='error'>{errors[name].message}</span>
      }
    </div>
  )
}

export default DatePicker