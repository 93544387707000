import { api } from '../api/api'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import SubLayout from '../components/SubLayout'
import { VerticalTable } from '../components/VerticalTable'
import { DiscrepancyData, DiscrepancyElement } from '../interfaces/Discrepancy'
import { Button, Input, Table, Menu, Modal, Form, Row, Col, DatePicker, InputNumber, message } from 'antd'
import { AircraftContext } from '../context/AircraftProvider'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { Report } from '../components/Report'
import { discrepancyColumns } from '../helpers/discrepancyColumns'
import PermissionWrapper from '../components/PermissionWrapper'


const Discrepancies = () => {

  const discrepanciesColumns = [
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Man hours',
      dataIndex: 'manHours',
      key: 'manHours',
    },
    {
      title: 'Created date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (text: string) => <>{moment(text).format('lll')}</>,
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render: (text: string, record: DiscrepancyElement) => <Link className='btn btnBlue' to={`/aircraft/${aircraftId}/discrepancies/${record.id}`}>View</Link>
    },
  ]
  const { aircraftId } = useParams<any>()

  const [discrepancies, setDiscrepancies] = useState<DiscrepancyElement[]>([])
  const { aircraftElement, setAircraftElement } = useContext(AircraftContext)
  const [doc, setDoc] = useState(false)

  useEffect(() => {
    if (!aircraftElement) {
      api.get(`/api/aircraft/${aircraftId}`).then(({ data }) => {
        setAircraftElement(data.aircraft)

      }).catch(error => console.log(error))
    }

  }, [aircraftId, setAircraftElement, aircraftElement])
  const [temp, setTemp] = useState<DiscrepancyElement[]>([])


  useEffect(() => {
    getAircraftDiscrepancies()
  }, [])

  const openModal = () => {
    setVisible(true)
  }

  const closeModal = () => {
    setVisible(false)
    form.resetFields()
  }
  const [visible, setVisible] = useState(false)

  const [form] = Form.useForm();

  const menu = (
    <Menu>
      <Menu.Item
        onClick={openModal}
        key='Create discrepancy'>
        Create discrepancy
      </Menu.Item>
      {
        doc ?
          <PDFDownloadLink
            document={<Report
              logo={aircraftElement.companyLogo}
              columns={discrepanciesColumns}
              aircraft={aircraftElement}
              title='Discrepancies report'
              data={discrepancies}
            />
            }
            fileName='Discrepancies_report.pdf'>
            {({ blob, url, loading, error }) =>
              loading ? 'Loading document...' : <Menu.Item >Download report</Menu.Item>
            }
          </PDFDownloadLink>
          :

          <Menu.Item
            onClick={() => { setDoc(true) }}
          >
            Generate report
          </Menu.Item>

      }
    </Menu>
  );

  const search = (e: any) => {
    setDiscrepancies(temp.filter(item => item.title.toLowerCase().includes(e.target.value.toLowerCase())))
  }

  const content = (
    <div
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div style={{
        marginRight: 10,
        flex: 1
      }}>
        <input
          className='input'
          onChange={search}
          style={{ width: '100%' }}
          placeholder='Search discrepancies' />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <PermissionWrapper
          role='create-edit'
          view='/mtx/discrepancies'
        >
          <button
            style={{ marginRight: 10 }}
            onClick={openModal}
            className='btn btn-blue'
          >
            Create discrepancy
          </button>
        </PermissionWrapper>
        <div>
          {
            doc ? <div className="download-btn-wrapper">
              <PDFDownloadLink
                document={<Report
                  logo={aircraftElement.companyLogo}
                  columns={discrepancyColumns}
                  aircraft={aircraftElement}
                  title='Discrepancies report'
                  data={discrepancies}
                />
                }
                fileName={`Discrepancies_report.pdf`}>
                {({ blob, url, loading, error }) =>
                  loading ? 'Loading document...' : <Button type='primary'>Download report</Button>
                }
              </PDFDownloadLink>

            </div> :

              <button
                className='btn btn-blue'
                onClick={() => { setDoc(true) }}
              >
                Generate report
              </button>

          }
        </div>
      </div>
    </div>
  )

  const searchInput = (
    <Input
      onChange={search}
      style={{ width: '100%' }}
      placeholder='Search discrepancies'
    />
  )

  const getAircraftDiscrepancies = () => {
    api.get<DiscrepancyData>(`/api/discrepancies/aircraft/${aircraftId}`).then(({ data }) => {
      setDiscrepancies(data.discrepancies)
      setTemp(data.discrepancies)
    }).catch(error => console.log(error))
  }

  const createDiscrepancy = () => {
    form.validateFields().then((values) => {
      api.post('/api/discrepancies', {
        ...values,
        dateIn: values.dateInOut[0],
        dateOut: values.dateInOut[1],
        status: 'New',
        aircraft: aircraftId
      }).then(() => {
        message.success('Discrepancy created')
        closeModal()
        getAircraftDiscrepancies()
      })
    })
  }

  return (
    <SubLayout
      menu={menu}
      content={content}
      title='Discrepancies'
      mobileSearch={searchInput}
    >
      <div>
        <div className="mobileTable">
          <VerticalTable columns={discrepanciesColumns} data={discrepancies} />
        </div>
        <div className="webTable">
          <Table columns={discrepanciesColumns} dataSource={discrepancies} />
        </div>

        <Modal
          visible={visible}
          onCancel={closeModal}
          title='Create discrepancy'
          centered
          width='70%'
          bodyStyle={{
            height: '40vh',
            overflowY: 'scroll'
          }}
          onOk={createDiscrepancy}

        >

          <Form
            form={form}
            layout="vertical"
            name="workOrderForm"
            initialValues={{
              location: "In House",
              authorizedForSignOff: false,
              downTimeIndicator: false
            }}
          >
            <Row gutter={[{ xs: 8, sm: 12, md: 24 }, { xs: 4, sm: 12, md: 8 }]}>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item
                  name="number"
                  label="Number"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6} >
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} >
                <Form.Item
                  name="dateInOut"
                  label="Date In / Date Out"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <DatePicker.RangePicker showTime format="YYYY-MM-DD HH:mm" />
                </Form.Item>
              </Col>
            </Row>


            <Row gutter={[{ xs: 8, sm: 12, md: 24 }, { xs: 4, sm: 12, md: 8 }]}>
              <Col xs={24} sm={12} >
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Input.TextArea style={{ resize: 'none' }} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6} >
                <Form.Item
                  name="manHours"
                  label="Man Hours"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >

                  <InputNumber />
                </Form.Item>
              </Col>
            </Row>
          </Form>

        </Modal>
      </div>
    </SubLayout>
  )
}

export default Discrepancies
