import { Table } from 'antd'
import { useEffect, useRef, useState } from 'react'
import Modal from '../../../components/Modal';
import TextArea from '../../../components/TextArea';
import Select from '../../../components/Select';
import { AircraftElement } from '../../../interfaces/Aircraft';
import { api } from '../../../api/api';
import { User } from '../../../interfaces/User';
import toast from 'react-hot-toast';
import { Flight } from '../../../interfaces/Flight';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid' // a plugin!
import Input from '../../../components/Input';
import { Form } from '../../../components/Form';
import DatePicker from '../../../components/DatePicker';
import FormGroup from '../../../components/FormGroup';
import { TimePicker } from '../../../components/TimePicker';
import Checkbox from '../../../components/Checkbox';
import PermissionWrapper from '../../../components/PermissionWrapper';
import { getUsers } from '../../../services/users/getUsers';
import { getFleet } from '../../../services/aircraft/getFleet';
import { getFlights } from '../../../services/flights/getFlights';
import Loading from '../../../components/Loading';
import { PDFDownloadLink, Text, View } from '@react-pdf/renderer';
import Report from '../../../components/FBO/Flights/Report';
import { styles } from '../../../helpers/styles';

const columns = [
  {
    title: 'Aircraft',
    dataIndex: 'aircraft',
    key: 'aircraft',
    render: (text: string, record: Flight) => record.aircraft.registrationNumber
  },
  {
    title: 'Departure',
    dataIndex: 'departure',
    key: 'departure',
    render: (text: string, record: Flight) => (
      <div>
        <span style={{
          display: 'block'
        }}>
          {record.departure.location}
        </span>

        <span style={{
          display: 'block'
        }}>
          {moment(record.departure.date).format('ddd, ll')}
        </span>

        <span style={{
          display: 'block'
        }}>
          {moment(record.departure.time).format('hh:mm a')}
        </span>
      </div>
    )
  },
  {
    title: 'Crew',
    dataIndex: 'crew',
    key: 'crew',
    render: (text: string, record: Flight) => (
      <div>
        {
          record.crew.map((user: User) => (
            <span style={{
              display: 'block'
            }}>- {user.name}</span>
          ))
        }
      </div>
    )
  },
  {
    title: 'Arrival',
    dataIndex: 'arrival',
    key: 'arrival',
    render: (text: string, record: Flight) => (
      <div>
        <span style={{
          display: 'block'
        }}>
          {record.arrival.location}
        </span>

        <span style={{
          display: 'block'
        }}>
          {moment(record.arrival.date).format('ddd, ll')}
        </span>

        <span style={{
          display: 'block'
        }}>
          {moment(record.arrival.time).format('hh:mm a')}
        </span>
      </div>
    )
  },
  {
    title: 'Preflight',
    dataIndex: 'preflight',
    key: 'preflight',
    render: (text: string, record: Flight) => record.preflight ? 'Yes' : 'No'
  },
  {
    title: 'Special Instructions',
    dataIndex: 'specialInstructions',
    key: 'specialInstructions',
  },
  {
    title: 'Details',
    dataIndex: 'details',
    key: 'details',
    render: (text: string, record: Flight) => <Link className='btn btn-auto btn-blue' to={`/fbo/flights/${record.id}`}>View</Link>
  }
];

const Flights = () => {

  const [visible, setVisible] = useState(false)

  const [users, setUsers] = useState<User[]>([])

  const [aircraft, setAircraft] = useState<AircraftElement[]>([])

  const [flights, setFlights] = useState<Flight[]>([])

  const [departureDate, setDepartureDate] = useState<Date | null>(new Date())

  const [arrivalDate, setArrivalDate] = useState<Date | null>(new Date())

  const [loading, setLoading] = useState(true)

  const ref = useRef<any>()

  const { push } = useHistory()

  const [view, setView] = useState('list')

  const [doc, setDoc] = useState(false)

  useEffect(() => {
    fetchUsers()
    fetchFleet()
    fetchFlights()
  }, [])

  const fetchUsers = async () => {
    try {
      const data = await getUsers()
      setUsers(data.users)
    } catch (error) {
      console.log(error);

    }
  }

  const fetchFleet = async () => {
    try {
      const data = await getFleet('fbo')
      setAircraft(data.aircraft)
    } catch (error) {
      console.log(error);

    }
  }

  const fetchFlights = async () => {
    try {
      const data = await getFlights()
      setFlights(data.flights)
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  }

  const renderForm = () => {
    return (
      <Form
        onSubmit={onSubmit}
        ref={ref}
      >
        <Select
          name='aircraft'
          label='Aircraft'
          required
          options={aircraft.map(item => (
            {
              label: item.registrationNumber,
              value: item.id
            }
          ))}
        />
        <FormGroup>
          <h3>Departure</h3>
          <DatePicker
            name='departureDate'
            label='Departure date'
            required
            selectedDate={departureDate}
            setSelectedDate={setDepartureDate}
          />
          <TimePicker
            name='departureTime'
            label='Departure time'
            placeholder='Departure time'
            required
          />
          <Input
            name='departureLocation'
            label='Location'
            placeholder='Location'
            required
          />
        </FormGroup>
        <FormGroup>
          <h3>Arrival</h3>
          <DatePicker
            name='arrivalDate'
            label='Arrival date'
            required
            selectedDate={arrivalDate}
            setSelectedDate={setArrivalDate}
          />
          <TimePicker
            name='arrivalTime'
            label='Arrival time'
            placeholder='Arrival time'
            required
          />
          <Input
            name='arrivalLocation'
            label='Location'
            placeholder='Location'
            required
          />
        </FormGroup>
        <Select
          name='crew'
          label='Crew'
          required
          isMulti
          options={users.map(item => (
            {
              label: item.name,
              value: item.id
            }
          ))}
        />
        <Checkbox
          style={{
            marginBottom: 20
          }}
          label='Preflight'
          name='preflight'
          id='preflight'
        />
        <Checkbox
          style={{
            marginBottom: 20
          }}
          label='Catering'
          name='catering'
          id='catering'
        />
        <Checkbox
          style={{
            marginBottom: 20
          }}
          label='Transportation'
          name='transportation'
          id='transportation'
        />
        <TextArea
          name='specialInstructions'
          label='Special Instructions'
          placeholder='Special Instructions'
        />
      </Form>
    )
  }

  const onSubmit = async (values: any) => {

    const postedFlight = {
      ...values,
      departure: {
        date: values.departureDate,
        time: values.departureTime,
        location: values.departureLocation
      },
      arrival: {
        date: values.arrivalDate,
        time: values.arrivalTime,
        location: values.arrivalLocation
      },
      crew: values.crew.map((user: any) => user.value),
      aircraft: values.aircraft.value
    }

    try {
      await api.post('/api/flights', postedFlight)
      toast.success('Sucesfully created new flight.')
      setVisible(false)
      fetchFlights()
    } catch (error: any) {
      toast.error(error.response.data.message)
    }
  }

  const reportCols = [
    {
      title: 'Aircraft',
      dataIndex: 'aircraft',
      key: 'aircraft',
      render: (text: string, record: Flight) => <Text style={styles.fontSmall}>{record.aircraft.registrationNumber}</Text>
    },
    {
      title: 'Departure',
      dataIndex: 'departure',
      key: 'departure',
      render: (text: string, record: Flight) => (
        <View>
          <Text style={styles.fontSmall}>
            {record.departure.location}
          </Text>

          <Text style={styles.fontSmall}>
            {moment(record.departure.date).format('ddd, ll')}
          </Text>

          <Text style={styles.fontSmall}>
            {moment(record.departure.time).format('hh:mm a')}
          </Text>
        </View>
      )
    },
    {
      title: 'Crew',
      dataIndex: 'crew',
      key: 'crew',
      render: (text: string, record: Flight) => (
        <View>
          {
            record.crew.map((user: User) => (
              <Text style={styles.fontSmall}>- {user.name}</Text>
            ))
          }
        </View>
      )
    },
    {
      title: 'Arrival',
      dataIndex: 'arrival',
      key: 'arrival',
      render: (text: string, record: Flight) => (
        <View>
          <Text style={styles.fontSmall}>
            {record.arrival.location}
          </Text>

          <Text style={styles.fontSmall}>
            {moment(record.arrival.date).format('ddd, ll')}
          </Text>

          <Text style={styles.fontSmall}>
            {moment(record.arrival.time).format('hh:mm a')}
          </Text>
        </View>
      )
    },
    {
      title: 'Preflight',
      dataIndex: 'preflight',
      key: 'preflight',
      render: (text: string, record: Flight) => record.preflight ? <Text style={styles.fontSmall}>Yes</Text> : <Text style={styles.fontSmall}>No</Text>
    },
    {
      title: 'Catering',
      dataIndex: 'catering',
      key: 'catering',
      render: (text: string, record: Flight) => record.catering ? <Text style={styles.fontSmall}>Yes</Text> : <Text style={styles.fontSmall}>No</Text>
    },
    {
      title: 'Transportation',
      dataIndex: 'transportation',
      key: 'transportation',
      render: (text: string, record: Flight) => record.transportation ? <Text style={styles.fontSmall}>Yes</Text> : <Text style={styles.fontSmall}>No</Text>
    },
    {
      title: 'Special Instructions',
      dataIndex: 'specialInstructions',
      key: 'specialInstructions',
    },
  ];

  if (loading) return <Loading />

  return (
    <>
      {
        flights.length === 0 ?

          <div className="empty">
            <div className="icon">
              <span className="material-symbols-rounded">
                flight_takeoff
              </span>
            </div>
            <h3>There are no flights yet</h3>
            <PermissionWrapper
              role='create-edit'
              view='/fbo/flights'
            >
              <button
                onClick={() => {
                  setVisible(true)
                }}
                className='btn btn-blue'>New flight</button>
            </PermissionWrapper>
          </div>

          :
          <div>
            <div className="header">
              <Link to='/fbo' className='btn btn-link btn-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                </svg>
                Back to FBO
              </Link>
            </div>
            <div className="header">
              <h2>Flights</h2>
              <div
                style={{
                  display: 'flex'
                }}
              >
                <PermissionWrapper
                  role='create-edit'
                  view='/fbo/flights'
                >
                  <button
                    style={{
                      marginRight: 20
                    }}
                    onClick={() => {
                      setVisible(true)
                    }}
                    className='btn btn-blue'>New flight</button>
                </PermissionWrapper>
                <div>
                  {
                    doc ? <div className="download-btn-wrapper">

                      <PDFDownloadLink
                        document={
                          <Report
                            columns={reportCols}
                            data={flights}
                          />
                        }
                        fileName={`Flights Report.pdf`}>
                        {({ blob, url, loading, error }) =>
                          loading ? 'Loading document...' : <button className='btn btn-blue'>Download report</button>
                        }
                      </PDFDownloadLink>
                    </div> :
                      <button
                        className='btn btn-blue'
                        onClick={() => { setDoc(true) }}
                      >
                        Generate report
                      </button>
                  }
                </div>
              </div>
            </div>

            <div className="header">
              <button onClick={() => {
                if (view === 'list') {
                  setView('calendar')
                } else {
                  setView('list')
                }
              }} className='btn btn-blue'>{view === 'list' ? 'Calendar' : 'List'} view</button>
            </div>

            {
              view === 'list' ?
                <div className="content">
                  <Table dataSource={flights} columns={columns} />
                </div> :
                <FullCalendar
                  headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek'
                  }}
                  eventClick={(event) => {
                    event.jsEvent.preventDefault();
                    push(event.event.url)
                  }}
                  plugins={[dayGridPlugin, timeGridPlugin]}
                  initialView="dayGridMonth"
                  events={flights.map(flight => ({
                    title: `${flight.aircraft.registrationNumber} - ${flight.arrival.location}`,
                    date: new Date(`${moment(flight.departure.date).format('YYYY-MM-DD')} ${moment(flight.departure.time).format('HH:mm')}`),
                    url: `/fbo/flights/${flight.id}`
                  }))}
                />
            }


          </div>
      }
      <Modal
        title='New flight'
        onCancel={() => {
          setVisible(false)
        }}
        onClose={() => {
          setVisible(false)
        }}
        onOk={() => {
          ref.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
        }}
        visible={visible}>
        <div>
          {renderForm()}
        </div>
      </Modal>
    </>

  )
}

export default Flights