import { CSSProperties, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import styles from '../styles/Table.module.scss'

interface Props {
  columns: any[]
  data: any[],
  view?: any
  style?: CSSProperties,
  fetchMoreData?: any,
  hasMore?: boolean,
}

const Table = ({ data, columns, view, style, fetchMoreData, hasMore }: Props) => {

  const [sortConfig, setSortConfig] = useState<any>(null);

  const styling: CSSProperties = {
    transform: sortConfig?.direction === 'ascending' ? `rotate(180deg)` : 'rotate(0)'
  }

  let sortedProducts = [...data];

  if (sortConfig !== null) {
    sortedProducts.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  }

  const requestSort = (key: string) => {

    let direction = 'ascending';
    if (sortConfig?.key === key && sortConfig?.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  }

  return (
    <div style={{ ...style }} className={styles.table}>
      <div className={styles.header}>
        {
          columns?.map((col: any, index: number) => (
            <div
              onClick={() => {
                requestSort(col.dataIndex)
              }}
              key={col.title}
              className={styles.col}
            >
              <span>
                {col.title}
              </span>
              <svg
                style={sortConfig?.key === col.dataIndex ? styling : undefined}
                xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          ))
        }

      </div>

      <InfiniteScroll
        dataLength={data.length}
        next={fetchMoreData}
        hasMore={hasMore || false}
        loader={

          <div style={{
            paddingTop: 40,
            paddingBottom: 40,
            textAlign: 'center'
          }}>
            <h4>Loading data...</h4>
          </div>
        }
      >
        {
          sortedProducts?.map((item: any, index: number) => {
            return (
              <div key={index} className={styles.row}>
                {
                  columns.map((col: any, index: number) => {
                    return (
                      <div key={index} className={styles.col}>
                        <div className={styles.colTitle}>
                          {
                            col.title
                          }
                        </div>
                        {
                          ('render' in col) ?
                            <div>
                              {col.render(item[col.dataIndex], item)}
                            </div> :
                            <span className={styles.fontSmall}>{item[col.dataIndex]}</span>
                        }
                      </div>
                    )
                  })
                }
              </div>
            )
          }

          )
        }
      </InfiniteScroll>
      {
        sortedProducts?.length === 0 &&
        <div className={styles.empty}>
          <img src="/empty-folder.png" alt="" />
          <span>No data</span>
        </div>
      }

    </div>
  )
}

export default Table