import { useContext, useEffect, useState } from 'react'
import { Tag, Menu, Modal, Form, Row, Col, Input, Select, DatePicker, message, Checkbox, Radio, List, Button, Table } from 'antd'
import SubLayout from '../components/SubLayout'
import { VerticalTable } from '../components/VerticalTable'
import { AircraftContext } from '../context/AircraftProvider'
import { useParams } from 'react-router'
import { api } from '../api/api'
import { WorkOrderData, WorkOrderElement } from '../interfaces/WorkOrder'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Status } from '../interfaces/Aircraft'
import { Task } from '../interfaces/Task'


const WorkOrders = () => {

  const workOrderColumns = [
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Date In',
      dataIndex: 'dateIn',
      key: 'dateIn',
      render: (text: string) => moment(text).format('ll')
    },
    {
      title: 'Date Out',
      dataIndex: 'dateOut',
      key: 'dateOut',
      render: (text: string) => moment(text).format('ll')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Items',
      dataIndex: 'items',
      key: 'items',
      render: (text: string, record: WorkOrderElement) => record.items.length
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render: (text: string, record: WorkOrderElement) => <Link className='btn btnBlue' to={`/aircraft/${aircraftId}/work-orders/${record.id}`}>View</Link>
    },
  ]

  const openModal = () => {
    setVisible(true)
  }

  const closeModal = () => {
    setVisible(false)
    form.resetFields()
    setSelected([])
    setTasks(tempTasks)
  }

  const createWorkOrder = () => {
    form.validateFields().then((values) => {
      let items = selected.map(item => item.id)
      api.post('/api/work-orders', {
        ...values,
        dateIn: values.dateInOut[0],
        dateOut: values.dateInOut[1],
        status: 'New',
        items,
        aircraft: aircraftId
      }).then(() => {
        message.success('Work order created')
        closeModal()
        closeTasks()
        getAircraftWorkOrders()
      })
    })
  }

  const search = (e: any) => {
    setWorkOrders(temp.filter(item => item.title.toLowerCase().includes(e.target.value.toLowerCase())))
  }

  const menu = (
    <Menu>
      <Menu.Item
        onClick={openModal}
        key='Create work order'>
        Create work order
      </Menu.Item>
    </Menu>
  );

  const content = (
    <div
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div style={{
        marginRight: 10,
        width: '100%',
      }}>
        <Input
          onChange={search}
          style={{ width: '100%' }}
          placeholder='Search work orders' />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          style={{ marginRight: 10 }}
          onClick={openModal}
          type='primary'>Create work order</Button>
      </div>
    </div>
  )

  const mobileSearch = (
    <Input
      onChange={search}
      style={{ width: '100%' }}
      placeholder='Search work orders'
    />
  )

  const openTasks = () => {
    setBrowse(true)

  }

  const closeTasks = () => {
    setBrowse(false)
  }

  const {aircraftElement, setAircraftElement} = useContext(AircraftContext)

  const { aircraftId } = useParams<any>()

  const [workOrders, setWorkOrders] = useState<WorkOrderElement[]>([])
  const [temp, setTemp] = useState<WorkOrderElement[]>([])

  const [visible, setVisible] = useState(false)

  const [browse, setBrowse] = useState(false)

  const [form] = Form.useForm();

  const [tasks, setTasks] = useState<Task[]>([])

  const [selected, setSelected] = useState<Task[]>([])

  const [tempTasks, setTempTasks] = useState<Task[]>([])


  useEffect(() => {
    getAircraftWorkOrders()
    getAircraftTasks()
  }, [])

  useEffect(() => {
    if (!aircraftElement) {
      api.get(`/api/aircraft/${aircraftId}`).then(({ data }) => {
        setAircraftElement(data.aircraft)

      }).catch(error => console.log(error))
    }

  }, [aircraftId, setAircraftElement, aircraftElement])

  const getAircraftWorkOrders = () => {
    api.get<WorkOrderData>(`/api/work-orders/aircraft/${aircraftId}`).then(({ data }) => {
      setWorkOrders(data.workOrders)
      setTemp(data.workOrders)
    }).catch(error => console.log(error))
  }

  const getAircraftTasks = () => {
    api.get<any>(`/api/tasks/aircraft/${aircraftId}`).then(({ data }) => {
      setTasks(data.tasks)
      setTempTasks(data.tasks)
    }).catch(error => console.log(error))
  }

  const columns = [
    {
      title: 'ATA',
      dataIndex: 'ata',
      key: 'ata',
    },
    {
      title: 'Task description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: Task) => <Tag color={record.status === 'OK' ? 'green' : 'red'} >{text}</Tag>
    },

    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render: (text: string, record: Task) => <Button
        onClick={() => {
          setSelected([...selected, record])
          setTasks(tasks.filter(task => task.id !== record.id))
          message.success('Task added')
        }}
        type='primary'>Add</Button>
    },

  ]

  return (
    <SubLayout
      content={content}
      menu={menu}
      title='Work orders'
      mobileSearch={mobileSearch}
    >
      <>
        <div className='workOrders'>
          <div className="mobileTable">
            <VerticalTable data={workOrders} columns={workOrderColumns} />
          </div>
          <div className="webTable">
            <Table dataSource={workOrders} columns={workOrderColumns} />
          </div>
        </div>

        <Modal
          visible={visible}
          title="Create work order"
          okText="Create"
          cancelText="Cancel"
          onCancel={closeModal}
          centered
          width='80%'
          onOk={createWorkOrder}
          bodyStyle={{
            height: '60vh',
            overflowY: 'scroll'
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="workOrderForm"
            initialValues={{
              location: "In House",
              authorizedForSignOff: false,
              downTimeIndicator: false
            }}
          >
            <Row gutter={[{ xs: 8, sm: 12, md: 24 }, { xs: 4, sm: 12, md: 8 }]}>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item
                  name="number"
                  label="Number"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6} >
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="dateInOut"
                  label="Date In / Date Out"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <DatePicker.RangePicker showTime format="YYYY-MM-DD HH:mm" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[{ xs: 8, sm: 12, md: 24 }, { xs: 4, sm: 12, md: 8 }]}>
              <Col xs={24} sm={12} >
                <Form.Item
                  name="description"
                  label="Work Order Description"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Input.TextArea style={{ resize: 'none' }} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="items"
                  label="Work Order Items"
                >
                  <List
                    size="small"
                    header={
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>Selected items</span>
                        <Button onClick={openTasks} type="primary">Add items</Button>
                      </div>
                    }
                    bordered
                    dataSource={selected}
                    renderItem={item =>
                      <List.Item
                        actions={[
                          <Button onClick={() => {
                            setTasks([...tasks, item])
                            setSelected(selected.filter(task => task.id !== item.id))
                          }} danger>Remove</Button>
                        ]
                        }
                      >{item.description}</List.Item>}
                  />

                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[{ xs: 8, sm: 12, md: 24 }, { xs: 4, sm: 12, md: 8 }]}>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item
                  name="icao"
                  label="ICAO"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6} >
                <Form.Item
                  name="appliesTo"
                  label="Applies To"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Select>
                    {
                      aircraftElement?.status.map((item: Status) =>
                        <Select.Option key={item.itemName} value={item.itemName}>
                          {item.itemName}
                        </Select.Option>
                      )
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[{ xs: 8, sm: 12, md: 24 }, { xs: 4, sm: 12, md: 8 }]}>
              <Col xs={24} sm={12} lg={6} >
                <Form.Item
                  name="authorizedForSignOff"
                  valuePropName="checked"
                >
                  <Checkbox> Authorized for Sign Off</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6} >
                <Form.Item
                  name="downTimeIndicator"
                  valuePropName="checked"
                >
                  <Checkbox>Enable Downtime Indicator</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6} >
                <Form.Item
                  name="location"
                >
                  <Radio.Group>
                    <Radio value="In House">In House</Radio>
                    <Radio value="Service Center">Service Center</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>


        <Modal
          visible={browse}
          title="Select items"
          okText="Ok"
          cancelText="Cancel"
          onCancel={closeTasks}
          centered
          width='80%'
          bodyStyle={{ height: '65vh', overflowY: 'scroll' }}
          onOk={closeTasks}

        >
          <div className="mobileTable">
            <VerticalTable columns={columns} data={tasks} />
          </div>

          <div className="webTable">
            <Table columns={columns} dataSource={tasks} />
          </div>
        </Modal>
      </>
    </SubLayout>
  )
}

export default WorkOrders
