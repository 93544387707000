import React, { FC, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { api } from '../api/api'
import DatePicker from './DatePicker'
import Input from './Input'
import Modal from './Modal'
import Select from './Select'
import styles from '../styles/UploadDocumentModal.module.scss'

interface Props {
  visible: boolean,
  setVisible: (visible: boolean) => void
}

const UploadDocumentModal: FC<Props> = ({ visible, setVisible }) => {

  const [intervalType, setIntervalType] = useState('')

  const [file, setFile] = useState('')

  const [startDate, setStartDate] = useState<Date | null>(new Date())

  const [dueDate, setDueDate] = useState<Date | null>(new Date())
  const [filePreview, setFilePreview] = useState<any>()

  const inputRef = useRef<any>()

  const { register, handleSubmit, control, reset, formState: { errors } } = useForm();


  const uploadFile = async (e: any) => {
    const file = e.target.files[0]
    if (!file) {
      return
    }

    const formData = new FormData()
    formData.append('file', file)
    try {
      const { data } = await api.post('/api/documents/upload/single', formData)
      setFile(data.Location)
      setFilePreview(file)
      toast.success('Uploaded file')

    } catch (error: any) {
      toast.error(error.response.data.message)
    }

  }

  const onSubmit = async (values: any) => {

    try {
      const savedDoc = {
        ...values,
        url: file,
        //user: user.id
      }

      await api.post(`/api/documents`, savedDoc)
      toast.success('Document saved succesfully')
      setVisible(false)
      reset()
      setFile('')
      setFilePreview(undefined)
      // fetchData()

    } catch (error: any) {
      toast.error(error.response.data.message)

    }
  }


  return (
    <>
      <Modal
        title='New document'
        visible={visible}
        onCancel={() => { setVisible(false) }}
        onClose={() => { setVisible(false) }}
        onOk={handleSubmit(onSubmit)}
      >
        <form>
          <Input
            name='name'
            label='Name'
            register={register}
            required
            errors={errors}
          />
          <DatePicker
            label='Start date'
            name='startDate'
            selectedDate={startDate}
            setSelectedDate={setStartDate}
            required
            control={control}
            errors={errors}
          />

          <Select
            label='Interval type'
            name='intervalType'
            control={control}
            required
            options={[
              {
                label: 'Date',
                value: 'date'
              },
              {
                label: 'O/C',
                value: 'oc'
              }
            ]}
            handleChange={(e: any) => {
              setIntervalType(e.value)
            }}

          />
          {
            intervalType === 'date' &&
            <DatePicker
              label='Due date'
              name='dueDate'
              selectedDate={dueDate}
              setSelectedDate={setDueDate}
              required
              control={control}
              errors={errors}
            />
          }

          <Select
            label='Category'
            name=' category'
            control={control}
            required
            options={[
              {
                label: 'General',
                value: 'general'
              },
              {
                label: 'Training',
                value: 'training'
              }
            ]}
          />
          {
            filePreview ?
              <div className="input-group">
                <span>Documento cargado</span>
                <div className={styles.filePreview}>
                  <span>{filePreview.name}</span>
                  <svg
                    onClick={() => {
                      setFilePreview(undefined)
                    }}
                    xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </div>
              </div>
              :
              <div className='input-group'>
                <label>Upload file</label>
                <input
                  onChange={uploadFile}
                  ref={inputRef}
                  style={{ display: 'none' }}
                  type='file'
                />
                <>
                  <div
                    className='dropzone'
                    onClick={() => { inputRef.current.click() }}
                  >
                    <span>
                      Upload file
                    </span>
                  </div>
                </>
              </div>
          }


        </form>
      </Modal>

    </>
  )
}

export default UploadDocumentModal